import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Icon,
  Heading,
  Flex,
  Text,
  Badge,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Input,
  Textarea,
  Stack,
  Radio,
  RadioGroup,
  useBreakpointValue,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { MdHome } from 'react-icons/md'
import { FaFistRaised } from 'react-icons/fa'
import { Helmet } from 'react-helmet'
import ogImage from '../images/og_image.jpeg'

const MitgliedPage = () => {
  return (
    <>
      <Helmet
        title="Sovie - Mitglied werden"
        meta={[
          {
            name: `description`,
            content: `Bestimme mit und werde laut für ein vielfältiges Österreich!`,
          },
          {
            property: `og:title`,
            content: `Sovie - Mitglied werden`,
          },
          {
            property: `og:url`,
            content: `https://www.sovie.or.at/mitglied-werden/`,
          },
          {
            property: `og:locale`,
            content: `de_DE`,
          },
          {
            property: `og:description`,
            content: `Bestimme mit und werde laut für ein vielfältiges Österreich!`,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            property: `og:site_name`,
            content: `SoVie Österreich`,
          },
          {
            property: `article:publisher`,
            content: `https://www.facebook.com/SoVie-%C3%96sterreich-114495926645116/`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: `sovie.or.at`,
          },
          {
            name: `twitter:image`,
            content: `https://sovie.or.at${ogImage}`,
          },
          {
            name: `twitter:title`,
            content: `Sovie - Mitglied werden`,
          },
          {
            name: `twitter:description`,
            content: `Bestimme mit und werde laut für ein vielfältiges Österreich!`,
          },
        ]}
      ></Helmet>
      <Box as="section" pt="5rem">
        <Box
          maxWidth="75rem"
          ml="auto"
          mr="auto"
          pl="1.5rem"
          pr="1.5rem"
        >
          <Breadcrumb
            spacing="8px"
            fontSize="sm"
            separator={<ChevronRightIcon color="red.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink as={GatsbyLink} to="/">
                <Icon as={MdHome} />
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Mitglied werden</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Box>
      <Box as="section" pt="2rem" pb="7.5rem">
        <Box maxW="75rem" ml="auto" mr="auto" pl="1.5rem" pr="1.5rem">
          <Flex m="-.5rem" justifyContent="center" wrap="wrap">
            <Box p=".5rem" mb="1rem" flex="1 1 28rem">
              <Box pt="1rem" pr={[null, null, '2']}>
                <Heading
                  as="h1"
                  fontSize={['3xl', '4xl', '5xl']}
                  ml="auto"
                  mr="auto"
                >
                  <Box>Get Active!</Box>
                </Heading>
                <Badge borderRadius="full" mr="2" colorScheme="red">
                  Mitglied werden
                </Badge>
                <Badge borderRadius="full" mr="2" colorScheme="red">
                  Bestimmen
                </Badge>
                <Badge borderRadius="full" colorScheme="red">
                  <Icon as={FaFistRaised} />
                </Badge>
                <Text
                  fontSize={['md', 'lg']}
                  mt="1.5rem"
                  opacity="0.7"
                >
                  Bestimme mit und werde laut für ein vielfältiges
                  Österreich!  <br />
                  <br />
                  Wir sind immer auf der Suche nach engagierten
                  Persönlichkeiten, die in ihrem Wirkungsbereich etwas
                  verändern wollen. Melde dich ganz unkompliziert mit
                  deinem Motivationssatz, in dem du uns erklärst,
                  warum du bei uns mitmachen möchtest. Wir kommen
                  innerhalb von 72 Stunden auf dich zu und vereinbaren
                  einen persönlichen Termin mit dir, damit wir uns
                  besser kennenlernen und alle deine offenen Fragen
                  klären können. <br />
                  <br />
                  Das beste später ist JETZT! <br />
                  <br />
                  Wir freuen uns auf deine Nachricht.
                </Text>
              </Box>
            </Box>
            <Box p=".5rem" flex="1 1 28rem">
              <Box
                p="1rem"
                borderWidth="1px"
                borderRadius="sm"
                overflow="hidden"
                bg="gray.50"
              >
                <Heading
                  as="h1"
                  fontSize={['3xl', '4xl', '5xl']}
                  ml="auto"
                  mr="auto"
                >
                  <Box colorScheme="red">Schreib uns an</Box>
                </Heading>
                <Box mt="1.5rem" fontSize={['md', 'lg']}>
                  <form
                    name="sovie-contact"
                    method="post"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input
                      type="hidden"
                      name="form-name"
                      value="sovie-contact"
                    />
                    <FormControl isRequired>
                      <FormLabel htmlFor="anrede" fontSize="inherit">
                        Anrede
                      </FormLabel>
                      <RadioGroup
                        colorScheme="red"
                        id="anrede"
                        name="anrede"
                      >
                        <Stack spacing={4} direction="row">
                          <Radio bg="white" value="Herr">
                            Herr
                          </Radio>
                          <Radio bg="white" value="Frau">
                            Frau
                          </Radio>
                          <Radio bg="white" value="Neutral">
                            Neutral
                          </Radio>
                        </Stack>
                      </RadioGroup>
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                    <FormControl mt="2" isRequired>
                      <FormLabel htmlFor="name" fontSize="inherit">
                        Name
                      </FormLabel>
                      <Input
                        name="name"
                        colorScheme="red"
                        bg="white"
                        id="name"
                        placeholder="Name"
                      />
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                    <FormControl mt="2" isRequired>
                      <FormLabel htmlFor="email" fontSize="inherit">
                        E-Mail
                      </FormLabel>
                      <Input
                        name="email"
                        colorScheme="red"
                        bg="white"
                        id="email"
                        placeholder="E-Mail"
                      />
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                    <FormControl mt="2" isRequired>
                      <FormLabel htmlFor="message" fontSize="inherit">
                        Nachricht
                      </FormLabel>
                      <Textarea
                        name="message"
                        colorScheme="red"
                        bg="white"
                        id="message"
                        placeholder="Nachricht eingeben..."
                        size="md"
                      />
                      <FormErrorMessage></FormErrorMessage>
                    </FormControl>
                    <Button
                      mt={4}
                      size={useBreakpointValue(['sm', 'md'])}
                      colorScheme="red"
                      type="submit"
                    >
                      Senden
                    </Button>
                  </form>
                </Box>
              </Box>{' '}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default MitgliedPage
